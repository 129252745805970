import * as React from 'react';

import { computed, observable, set } from 'mobx';

import { ICartItem, ICartItemCamel } from '~api/IOrderHistoryApi';
import { inventoryStatuses, ProductTypes, shoppableStatuses } from '~constants/InventoryStatuses';
import { diContainer, serviceNames } from '~setup/diContainer';
import { getProductType, getSkuId, getThumbnail } from '~utils/orderedItemHelpers';
import { processValue, toNumber } from '~utils/ToCamelCase';

export class OrderedItem {
    @observable public skuId: ICartItemCamel['productCode'];
    @observable public quantity: ICartItemCamel['itemQuantity'];
    @observable public inventoryStatus: ICartItemCamel['inventoryStatus'];
    @observable public isSampleItem: ICartItemCamel['isSampleItem'];
    @observable public memberOfCollectionType: ICartItemCamel['memberOfCollectionType'];
    @observable public shade: ICartItemCamel['shade'];
    @observable public skuIdRef: React.RefObject<HTMLDivElement> = React.createRef();
    @observable public thumbnail: ICartItemCamel['thumbnail'];
    @observable public productType: keyof typeof ProductTypes;
    @observable public productName: ICartItemCamel['prodRgnName'];
    @observable public productSize: ICartItemCamel['productSize'];
    @observable public url: ICartItemCamel['url'];
    @observable public discountedPrice: ICartItemCamel['formattedDiscountedUnitPrice'];
    @observable public fullPrice: ICartItemCamel['formattedUnitPrice'];

    public static fromJSON(apiData: ICartItem): OrderedItem {
        const orderInfo = new OrderedItem();
        const isShaded = processValue(apiData.shaded) && !!apiData.hex_value;

        const modifiedData = {
            inventoryStatus: apiData.inventory_status,
            isSampleItem: apiData.isSampleItem,
            memberOfCollectionType: apiData.member_of_collection_type,
            quantity: toNumber(apiData.item_quantity),
            skuId: getSkuId(apiData),
            thumbnail: getThumbnail(apiData),
            productName: apiData.prod_rgn_name,
            productSize: apiData.product_size,
            productType: getProductType(apiData),
            discountedPrice: apiData.formatted_discounted_unit_price,
            fullPrice: apiData.formatted_unit_price,
            url: apiData.url,
            ...(isShaded && {
                shade: { name: apiData.shadename || '', hex: `#${apiData.hex_value}` }
            })
        };

        if (!modifiedData.fullPrice) {
            const priceFormatter = diContainer.get(serviceNames.priceFormatter);
            modifiedData.fullPrice = priceFormatter.formatPriceWithCurrency(apiData.unit_price);
        }

        set(orderInfo, modifiedData);

        return orderInfo;
    }

    @computed public get reorderConfig() {
        const { skuId, quantity, inventoryStatus, memberOfCollectionType } = this;

        const reorderCtaConfig = {
            ctaType: 'add_to_bag',
            quantity,
            selectedSku: {
                skuId,
                quantity,
                parentProduct: {
                    skus: [skuId],
                    defaultSku: {}
                },
                inventoryStatus: inventoryStatuses[inventoryStatus as number],
                isShoppable:
                    shoppableStatuses.has(inventoryStatus) && memberOfCollectionType !== 'UKIT'
            }
        };

        return reorderCtaConfig;
    }
}
