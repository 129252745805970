import { OrderHistoryApiSdk } from '~api/OrderHistoryApiSdk';
import { IAppConfigWithRequestedBUIDKeys } from '~domain/entities/IConfig';
import { ConfigRepository } from '~repositories/ConfigRepository';
import { diContainer, serviceNames } from '~setup/diContainer';

export class OrderHistoryServiceStartedUseCase {
    private apiSdk: OrderHistoryApiSdk;
    private configRepository: ConfigRepository;
    private configs: IAppConfigWithRequestedBUIDKeys;

    constructor(configs: IAppConfigWithRequestedBUIDKeys) {
        this.apiSdk = diContainer.get(serviceNames.orderHistoryApiSdk);
        this.configRepository = diContainer.get(serviceNames.configRepository);
        this.configs = configs;
    }

    public execute = async () => {
        await this.configRepository.fetchConfigs(this.configs);
        this.apiSdk.setConfigs(this.configRepository.config);
    };
}
