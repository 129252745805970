import { action, computed, observable } from 'mobx';

import { query, serviceQueries, ServiceQueriesResponseType } from '@estee/elc-service-bus';

enum Status {
    IDLE = 'IDLE',
    LOADING = 'LOADING',
    ERROR = 'ERROR',
    LOADED = 'LOADED'
}

type AuthState = typeof serviceQueries.GET_USER_AUTH_STATE;
type UserAuthState = Pick<ServiceQueriesResponseType, AuthState>[AuthState];

export const authStates: { [key: string]: UserAuthState } = {
    SIGNEDIN: 'SIGNEDIN',
    ANONYMOUS: 'ANONYMOUS',
    RECOGNIZED: 'RECOGNIZED'
};

export class AuthenticatedRepository {
    private initialPromise: Promise<void>;
    @observable public authState: UserAuthState = authStates.ANONYMOUS;
    @observable private status: Status = Status.IDLE;

    @action public ensureData(page?: number) {
        if (this.isUnfulfilled || page) {
            this.initialPromise = this.loadData();
        }

        return this.initialPromise;
    }

    @action private async loadData() {
        try {
            this.status = Status.LOADING;
            this.authState = (await query(serviceQueries.GET_USER_AUTH_STATE)) as UserAuthState;

            if (this.authState === authStates.SIGNEDIN) {
                await this.fetchData();
                this.status = Status.LOADED;
            }
        } catch (err) {
            this.status = Status.ERROR;
            throw new Error(err.message);
        }
    }

    @action public async fetchData() {}

    @computed public get isUnfulfilled() {
        return [Status.IDLE, Status.ERROR].includes(this.status);
    }
}
