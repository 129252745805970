import { action, computed } from 'mobx';

import { ViewController } from '@estee/elc-service';

import { OrderHistoryRepository } from '~repositories/OrderHistoryRepository';
import { LoadOrderHistoryUseCase } from '~use-cases/LoadOrderHistoryUseCase';

interface IOrderHistoryInTransitController {
    orderHistoryRepository: OrderHistoryRepository;
}

class OrderHistoryInTransitController extends ViewController {
    private orderHistoryRepository: OrderHistoryRepository;

    constructor({ orderHistoryRepository }: IOrderHistoryInTransitController) {
        super();
        this.orderHistoryRepository = orderHistoryRepository;
    }

    @action
    public loaded = async () => {
        this.isLoading = true;
        await new LoadOrderHistoryUseCase().execute();
        this.isLoading = false;
    };

    @computed public get data() {
        return {
            orderHistory: this.orderHistoryRepository.orderHistory
        };
    }
}

export default OrderHistoryInTransitController;
