export const getCurrentPage = (): number => {
    const match = window.location.search.match(/page=(.*)/);
    const page = Number(match?.[1]);

    return page || 1;
};

export const getPages = (activePage: number, lastPage: number): number[] => {
    const delta = 1;
    const minPagesShown = 5;
    const leftBoundary =
        activePage - delta < lastPage - minPagesShown + 1
            ? activePage - delta
            : lastPage - minPagesShown + 1;
    const rightBoundary =
        activePage + delta > minPagesShown - 1 ? activePage + delta : minPagesShown;
    const pageNumbers: number[] = [];
    const pages: number[] = [];
    let prevPage: number;

    for (let page = 1; page <= lastPage; page++) {
        if (page === 1 || page === lastPage || (page >= leftBoundary && page <= rightBoundary)) {
            pageNumbers.push(page);
        }
    }

    pageNumbers.forEach((page) => {
        if (prevPage) {
            if (page - prevPage === 2) {
                pages.push(prevPage + 1);
            } else if (page - prevPage !== 1) {
                pages.push(0);
            }
        }

        pages.push(page);

        prevPage = page;
    });

    return pages;
};
