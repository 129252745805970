import { action, computed, observable } from 'mobx';

import { ViewController } from '@estee/elc-service';

import { OrderHistoryRepository } from '~repositories/OrderHistoryRepository';
import { LoadOrderHistoryUseCase } from '~use-cases/LoadOrderHistoryUseCase';
import { getCurrentPage, getPages } from '~utils/paginationHelpers';

interface IOrdersListController {
    orderHistoryRepository: OrderHistoryRepository;
}

class OrdersListController extends ViewController {
    private orderHistoryRepository: OrderHistoryRepository;
    private ordersPerPage = 5;
    @observable private isLoadingPageOrders: boolean;

    constructor({ orderHistoryRepository }: IOrdersListController) {
        super();
        this.orderHistoryRepository = orderHistoryRepository;
    }

    @action public loaded = async () => {
        this.handleHistoryChange();

        this.isLoading = true;
        this.orderHistoryRepository.setActivePage(getCurrentPage());
        await new LoadOrderHistoryUseCase().execute();
        this.isLoading = false;
    };

    @action public setActivePage = async (page: number) => {
        if (this.isLoadingPageOrders) {
            this.orderHistoryRepository.abortFetch();
        }

        this.orderHistoryRepository.setActivePage(page);

        if (!this.orderHistoryRepository.orderHistory?.pages[page]?.orders) {
            this.isLoadingPageOrders = true;
            await new LoadOrderHistoryUseCase().execute();
            this.isLoadingPageOrders = false;
        }
    };

    @computed public get data() {
        const { orderHistory, activePage } = this.orderHistoryRepository;

        return {
            activePage,
            orders: orderHistory?.pages[activePage]?.orders,
            totalPages: this.totalPages,
            isLoadingPageOrders: this.isLoadingPageOrders
        };
    }

    @computed public get totalPages() {
        return Math.ceil(
            this.orderHistoryRepository.orderHistory?.totalOrders / this.ordersPerPage
        );
    }

    @computed public get pages(): number[] {
        return getPages(this.orderHistoryRepository.activePage, this.totalPages);
    }

    private handleHistoryChange() {
        window.onpopstate = () => {
            const currentPage = getCurrentPage();

            if (this.orderHistoryRepository.activePage !== currentPage) {
                this.setActivePage(currentPage);
            }
        };
    }
}

export default OrdersListController;
