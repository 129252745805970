import { Container } from 'hypo-container';

import { BusinessUnitIdParser, DateFormatter, PriceFormatter } from '@estee/elc-formatters';
import { HttpTransport } from '@estee/elc-http-transport';
import { getDecorators } from '@estee/elc-service';

import { OrderHistoryApiSdk } from '~api/OrderHistoryApiSdk';
import { Config } from '~domain/entities/Config';
import { OrderHistory } from '~domain/entities/OrderHistory';
import LatestOrderController from '~exported/latest-order/LatestOrderController';
import OrderHistoryInTransitController from '~exported/order-history-in-transit/OrderHistoryInTransitController';
import OrderInformationController from '~exported/order-information/OrderInformationController';
import OrdersListController from '~exported/orders-list/OrdersListController';
import { ConfigRepository } from '~repositories/ConfigRepository';
import { OrderHistoryRepository } from '~repositories/OrderHistoryRepository';

import { OrderHistoryService } from './OrderHistoryService';
import { ServiceBusController } from './ServiceBusController';

export const diContainer = new Container();
export const serviceNames = {
    businessUnitIdParser: 'BUSINESS_UNIT_ID_PARSER',
    config: 'CONFIG',
    configRepository: 'CONFIG_REPOSITORY',
    dateFormatter: 'DATE_FORMATTER',
    httpTransport: 'HTTP_TRANSPORT',
    latestOrderController: 'LATEST_ORDER_VIEW_CONTROLLER',
    orderHistory: 'ORDER_HISTORY',
    orderHistoryApiSdk: 'ORDER_HISTORY_API_SDK',
    orderHistoryRepository: 'ORDER_HISTORY_REPOSITORY',
    orderHistoryService: 'ORDER_HISTORY_SERVICE',
    orderInformationController: 'ORDER_INFORMATION_VIEW_CONTROLLER',
    orderInTransitController: 'ORDER_HISTORY_IN_TRANSIT_VIEW_CONTROLLER',
    ordersListController: 'ORDERS_LIST_VIEW_CONTROLLER',
    priceFormatter: 'PRICE_FORMATTER',
    serviceBusController: 'SERVICE_BUS_CONTROLLER'
};

export const setConfig = (configJSON: Record<string, unknown>) => {
    diContainer.config = configJSON;
};

diContainer
    .register(serviceNames.config, (c) => {
        const appConfig = c.config;

        return new Config(appConfig);
    })
    .register(serviceNames.orderHistoryService, (c) => {
        const service = new OrderHistoryService((config) => {
            setConfig(config);
            const serviceBus = c.get(serviceNames.serviceBusController);

            return serviceBus;
        });

        return service;
    })
    .register(serviceNames.httpTransport, () => new HttpTransport())
    .register(serviceNames.orderHistory, () => new OrderHistory())
    .register(serviceNames.configRepository, (c) => {
        const entity = c.get(serviceNames.config);

        return new ConfigRepository(entity);
    })
    .register(serviceNames.orderHistoryRepository, (c) => {
        const api = c.get(serviceNames.orderHistoryApiSdk);

        return new OrderHistoryRepository(api, c.get(serviceNames.orderHistory));
    })
    .register(serviceNames.latestOrderController, (c) => {
        const repository = c.get(serviceNames.orderHistoryRepository);

        return new LatestOrderController(repository);
    })
    .register(serviceNames.orderInTransitController, (c) => {
        const orderHistoryRepository = c.get(serviceNames.orderHistoryRepository);

        return new OrderHistoryInTransitController({ orderHistoryRepository });
    })
    .register(serviceNames.orderInformationController, (c) => {
        const orderHistoryRepository = c.get(serviceNames.orderHistoryRepository);

        return new OrderInformationController({ orderHistoryRepository });
    })
    .register(serviceNames.ordersListController, (c) => {
        const orderHistoryRepository = c.get(serviceNames.orderHistoryRepository);

        return new OrdersListController({ orderHistoryRepository });
    })
    .register(serviceNames.serviceBusController, (c) => new ServiceBusController())
    .register(serviceNames.orderHistoryApiSdk, (c) => {
        const transport = c.get(serviceNames.httpTransport);

        return new OrderHistoryApiSdk(transport);
    })
    .register(
        serviceNames.businessUnitIdParser,
        diContainer.factory((c) => {
            return new BusinessUnitIdParser(c.config.businessUnitId);
        })
    )
    .register(
        serviceNames.dateFormatter,
        diContainer.factory((c) => {
            const { locale } = c.get(serviceNames.businessUnitIdParser);

            const dateFormatterConfig = { locale };

            return new DateFormatter(dateFormatterConfig);
        })
    )
    .register(
        serviceNames.priceFormatter,
        diContainer.factory((c) => {
            const businessUnitIdParser = c.get(serviceNames.businessUnitIdParser);
            const { config } = c.get(serviceNames.config);

            const priceFormatterConfig = {
                currency: config.currency,
                locale: businessUnitIdParser.locale
            };

            return new PriceFormatter(priceFormatterConfig);
        })
    );

const { lazyInject } = getDecorators(diContainer);

export { lazyInject };
