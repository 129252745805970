enum InventoryStatus {
    Active = 'Active',
    TempOutOfStock = 'Temporarily out of stock',
    ComingSoon = 'Coming Soon',
    SoldOut = 'Sold Out',
    FreePromo = 'Free Promo',
    DoNotDisplay = 'Do Not Display',
    Inactive = 'Inactive',
    PreOrder = 'Pre Order'
}

export const inventoryStatuses: Record<number, string> = {
    1: InventoryStatus.Active,
    2: InventoryStatus.TempOutOfStock,
    3: InventoryStatus.ComingSoon,
    4: InventoryStatus.DoNotDisplay,
    5: InventoryStatus.Inactive,
    6: InventoryStatus.FreePromo,
    7: InventoryStatus.SoldOut,
    8: InventoryStatus.PreOrder
};

export const unavailableStatuses = new Set([4, 5, 7]);
export const shoppableStatuses = new Set([1, 2]);

export enum ProductTypes {
    Donation,
    Sample,
    Giftwrap,
    Regular,
    Unavailable
}
