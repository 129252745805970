import { action, computed, observable } from 'mobx';

import { ViewController } from '@estee/elc-service';

import { OrderedItem } from '~domain/entities/OrderedItem';
import { OrderHistoryRepository } from '~repositories/OrderHistoryRepository';
import { LoadOrderHistoryUseCase } from '~use-cases/LoadOrderHistoryUseCase';

class LatestOrderController extends ViewController {
    private orderHistoryRepository: OrderHistoryRepository;
    private MORE_PRODUCTS_COUNT: number = 2;
    @observable private lastSkuIndex: number = 2; // we display up to 3 items initially
    @observable public orderedItems: OrderedItem[];

    constructor(orderHistoryRepository: OrderHistoryRepository) {
        super();
        this.orderHistoryRepository = orderHistoryRepository;
    }

    @action public loaded = async () => {
        this.isLoading = true;
        await new LoadOrderHistoryUseCase().execute();
        this.setOrderedItems();
        this.isLoading = false;
    };

    @action private setOrderedItems = () => {
        const orderedItemsRendered: OrderedItem[] = [];
        const latestOrderSkus = this.orderHistoryRepository.orderHistory?.latestOrderSkus;
        const skusQuantity = latestOrderSkus?.length;
        const lastSkuIndex = this.skusLeftToShow > 0 ? this.lastSkuIndex : skusQuantity - 1;

        for (let lineItemIndex = 0; lineItemIndex <= lastSkuIndex; lineItemIndex++) {
            const orderedItem: OrderedItem = latestOrderSkus[lineItemIndex];

            orderedItemsRendered.push(orderedItem);
        }

        this.orderedItems = orderedItemsRendered;
    };

    @computed public get data() {
        return {
            latestOrder: this.orderHistoryRepository.orderHistory?.latestOrder,
            orderedItems: this.orderedItems
        };
    }

    @computed private get skusLeftToShow() {
        const skusQuantity = this.orderHistoryRepository.orderHistory?.latestOrderSkus?.length;

        return skusQuantity - this.lastSkuIndex - 1;
    }

    @computed public get remainingProductsToLoad() {
        return this.skusLeftToShow >= this.MORE_PRODUCTS_COUNT
            ? this.MORE_PRODUCTS_COUNT
            : this.skusLeftToShow;
    }

    @action public scrollToOneBeforeLastProduct = (lastSkuIndex: number) => {
        const { latestOrderSkus } = this.orderHistoryRepository.orderHistory;
        const lastSkuIndexRef = latestOrderSkus[lastSkuIndex].skuIdRef;

        lastSkuIndexRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    };

    @action public onLoadMoreProducts = () => {
        this.scrollToOneBeforeLastProduct(this.lastSkuIndex);
        this.lastSkuIndex += this.remainingProductsToLoad;
        this.setOrderedItems();
    };
}

export default LatestOrderController;
