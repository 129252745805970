(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("HypoContainer"), require("HttpTransport"), require("mobx"), require("react"), require("ElcServiceBus"), require("ELCLogger"), require("mobx-react"), require("styled-components"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["HypoContainer", "HttpTransport", "mobx", "react", "ElcServiceBus", "ELCLogger", "mobx-react", "styled-components", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["pg-service-order-history"] = factory(require("HypoContainer"), require("HttpTransport"), require("mobx"), require("react"), require("ElcServiceBus"), require("ELCLogger"), require("mobx-react"), require("styled-components"), require("react-dom"));
	else
		root["pg-service-order-history"] = factory(root["ElcRuntime"]["HypoContainer"], root["ElcRuntime"]["HttpTransport"], root["ElcRuntime"]["mobx"], root["ElcRuntime"]["React"], root["ElcServiceBus"], root["ElcRuntime"]["ELCLogger"], root["ElcRuntime"]["mobxReact"], root["ElcRuntime"]["styled5"], root["ElcRuntime"]["ReactDom"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_hypo_container__, __WEBPACK_EXTERNAL_MODULE__estee_elc_http_transport__, __WEBPACK_EXTERNAL_MODULE_mobx__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_elc_service_bus__, __WEBPACK_EXTERNAL_MODULE__estee_elc_logging__, __WEBPACK_EXTERNAL_MODULE_mobx_react__, __WEBPACK_EXTERNAL_MODULE_styled_components__, __WEBPACK_EXTERNAL_MODULE_react_dom__) => {
return 