import { ICartItem } from '~api/IOrderHistoryApi';
import { ProductTypes, unavailableStatuses } from '~constants/InventoryStatuses';

type SkuIdPath = ICartItem['medium_image'][0];

const extractSkudId = (path: SkuIdPath, regex: RegExp): string =>
    path?.match(regex)?.[0]?.replace(/sku|_/g, '') || '';

export const getSkuId = (cartItem: ICartItem): string => {
    if (cartItem.is_order_level_gift_wrap) {
        return extractSkudId(cartItem.medium_image[0], /sku.+?_/);
    }

    return cartItem.product_code || '';
};

export const getProductType = (cartItem: ICartItem): string => {
    if (unavailableStatuses.has(cartItem.inventory_status as number)) {
        return ProductTypes[ProductTypes.Unavailable];
    }
    if (cartItem.isDonationItem) {
        return ProductTypes[ProductTypes.Donation];
    }
    if (cartItem.isSampleItem) {
        return ProductTypes[ProductTypes.Sample];
    }
    if (cartItem.is_order_level_gift_wrap) {
        return ProductTypes[ProductTypes.Giftwrap];
    }

    return ProductTypes[ProductTypes.Regular];
};

export const getThumbnail = (cartItem: ICartItem): string => {
    if (cartItem.is_order_level_gift_wrap) {
        return cartItem.medium_image[0];
    }

    return cartItem.thumbnail;
};
