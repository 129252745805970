import { action, computed, observable } from 'mobx';

import { IOrderHistoryResponse } from '~api/IOrderHistoryApi';
import { OrderHistoryApiSdk } from '~api/OrderHistoryApiSdk';
import { OrderHistory } from '~domain/entities/OrderHistory';
import { AuthenticatedRepository } from './AuthenticatedRepository';

export class OrderHistoryRepository extends AuthenticatedRepository {
    private apiSdk: OrderHistoryApiSdk;
    public abortController = new AbortController();
    @observable private orderData: OrderHistory;
    @observable public _activePage: number;
    @observable public targetOrderHistory: OrderHistory;

    constructor(apiSdk: OrderHistoryApiSdk, orderHistory: OrderHistory) {
        super();
        this.apiSdk = apiSdk;
        this.targetOrderHistory = orderHistory;
    }

    public abortFetch = () => {
        this.abortController.abort();
        this.abortController = new AbortController();
    };

    @action public fetchOrderHistory = async () => {
        return super.ensureData(this._activePage);
    };

    @action public fetchData = async () => {
        let data: IOrderHistoryResponse;

        if (this._activePage) {
            data = <IOrderHistoryResponse>(
                await this.apiSdk.getPageOrders(this._activePage, this.abortController.signal)
            );
        } else {
            data = <IOrderHistoryResponse>await this.apiSdk.getSessionOrder();
        }

        const orderHistoryData = OrderHistory.fromJSON(
            data,
            this.targetOrderHistory,
            this._activePage
        );
        this.setOrderData(orderHistoryData);
    };

    @action public setOrderData = (orderData: OrderHistory) => {
        this.orderData = orderData;
    };

    @action public setActivePage = (page: number) => {
        this._activePage = page;
    };

    @computed
    public get orderHistory() {
        return this.orderData;
    }

    @computed
    public get activePage() {
        return this._activePage;
    }
}
