import { OrderHistoryRepository } from '~repositories/OrderHistoryRepository';
import { diContainer, serviceNames } from '~setup/diContainer';

export class LoadOrderHistoryUseCase {
    private orderHistoryRepository: OrderHistoryRepository;

    constructor() {
        this.orderHistoryRepository = diContainer.get(serviceNames.orderHistoryRepository);
    }

    public async execute() {
        await this.orderHistoryRepository.fetchOrderHistory();
    }
}
