import { Service } from '@estee/elc-service';
import { ServiceInitializer } from '@estee/elc-universal-utils';
import { IServiceAppConfig } from '~domain/entities/IConfig';

import {
    ACCOUNT_LATEST_ORDER,
    ACCOUNT_ORDER_INFORMATION,
    ACCOUNT_ORDERS_LIST
} from '@estee/elc-service-view-names';
import { OrderHistoryServiceStartedUseCase } from '~use-cases/OrderHistoryServiceStartedUseCase';

import { diContainer, serviceNames } from './diContainer';

const { name, version } = __serviceInfo__;

// todo: add to @estee/elc-service-view-names
export const ORDER_HISTORY_IN_TRANSIT = 'order-history-in-transit';

export class OrderHistoryService extends Service {
    constructor(serviceIntializer: ServiceInitializer) {
        super(name, version, serviceIntializer);

        this.registerView(
            ORDER_HISTORY_IN_TRANSIT,
            () =>
                import(
                    /* webpackChunkName: 'OrderHistoryInTransit' */
                    '../exported/order-history-in-transit/OrderHistoryInTransit'
                ),
            () => diContainer.get(serviceNames.orderInTransitController)
        );

        this.registerView(
            ACCOUNT_LATEST_ORDER,
            () =>
                import(
                    /* webpackChunkName: 'OrderHistoryLatestOrder' */
                    '../exported/latest-order/LatestOrder'
                ),
            () => diContainer.get(serviceNames.latestOrderController)
        );

        this.registerView(
            ACCOUNT_ORDER_INFORMATION,
            () =>
                import(
                    /* webpackChunkName: 'OrderHistoryOrderInformation' */
                    '../exported/order-information/OrderInformation'
                ),
            () => diContainer.get(serviceNames.orderInformationController)
        );

        this.registerView(
            ACCOUNT_ORDERS_LIST,
            () =>
                import(
                    /* webpackChunkName: 'OrderHistoryOrdersList' */
                    '../exported/orders-list/OrdersList'
                ),
            () => diContainer.get(serviceNames.ordersListController)
        );
    }
    public async start(appConfig: IServiceAppConfig) {
        const service = await super.start(appConfig);
        const requestedBuidConfigs = ['brand.id', 'language', 'region', 'currency'];

        const useCase = new OrderHistoryServiceStartedUseCase({
            appConfig,
            requestedBuidConfigs
        });

        await useCase.execute();

        return service;
    }
}
