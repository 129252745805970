import { action, computed } from 'mobx';

import { ViewController } from '@estee/elc-service';

import { OrderHistoryRepository } from '~repositories/OrderHistoryRepository';
import { LoadOrderHistoryUseCase } from '~use-cases/LoadOrderHistoryUseCase';

interface IOrderInformationController {
    orderHistoryRepository: OrderHistoryRepository;
}

class OrderInformationController extends ViewController {
    private orderHistoryRepository: OrderHistoryRepository;

    constructor({ orderHistoryRepository }: IOrderInformationController) {
        super();
        this.orderHistoryRepository = orderHistoryRepository;
    }

    @action
    public loaded = async () => {
        this.isLoading = true;
        await new LoadOrderHistoryUseCase().execute();
        this.isLoading = false;
    };

    @computed public get data() {
        return {
            latestOrder: this.orderHistoryRepository.orderHistory?.latestOrder
        };
    }
}

export default OrderInformationController;
