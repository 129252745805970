import { computed, observable, set } from 'mobx';

import { IOrderHistoryResponse, IOrder } from '~api/IOrderHistoryApi';

import { Order } from './Order';

interface IPages {
    [key: string]: { orders: Order[] };
}

export class OrderHistory {
    @observable public orders: Order[] = [];
    @observable private _totalOrders: number;
    @observable private _pages: IPages = {};

    public static fromJSON(
        { order_history = [], total_orders }: IOrderHistoryResponse,
        targetOrderHistory: OrderHistory,
        page: number
    ): OrderHistory {
        const orders = order_history.map((order: IOrder) => Order.fromJSON(order));

        set(targetOrderHistory, {
            orders,
            _totalOrders: total_orders,
            _pages: { ...targetOrderHistory._pages, [page]: { orders } }
        });

        return targetOrderHistory;
    }

    @computed public get latestOrder() {
        return this.orders[0];
    }

    @computed public get latestOrderSkus() {
        return this.latestOrder?.itemsOrdered;
    }

    @computed public get totalOrders() {
        return this._totalOrders;
    }

    @computed public get pages() {
        return this._pages;
    }
}
