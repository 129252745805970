import { HttpTransport } from '@estee/elc-http-transport';
import { root } from '@estee/elc-universal-utils';

import { ConfigRepository } from '~repositories/ConfigRepository';

import { IOrderHistoryResponse } from './IOrderHistoryApi';

type IPgApiConfigKeys = 'apiAuthToken' | 'brandId' | 'regionId';
type IPgApiConfigs = Pick<ConfigRepository['config'], IPgApiConfigKeys>;

interface IGlobalHeaders {
    Authorization: string;
    AuthorizationCredentials: string;
}

const BASE_API_PATH = '/rest/api/v1/ra';

export class OrderHistoryApiSdk {
    private httpTransport: HttpTransport;
    private apiAuthToken: ConfigRepository['config']['apiAuthToken'];
    private brand: ConfigRepository['config']['brandId'];
    private region: ConfigRepository['config']['regionId'];

    constructor(httpTransport: HttpTransport) {
        this.httpTransport = httpTransport;
    }

    public setConfigs({ apiAuthToken, brandId, regionId }: IPgApiConfigs) {
        this.brand = brandId;
        this.region = regionId;
        this.apiAuthToken = apiAuthToken;

        this.httpTransport.addGlobalHeaders(this.getGlobalHeaders());
    }

    private getAuthToken = (): string => this.apiAuthToken;

    private getGlobalHeaders = (): IGlobalHeaders => {
        const auth = btoa(this.getAuthToken());
        const AuthorizationCredentials = `Basic ${auth}`;
        const Authorization = AuthorizationCredentials;

        return {
            Authorization,
            AuthorizationCredentials
        };
    };

    // @todo: Extract this into an elc-perlgem-api package
    private getBaseUrl = (): string => `${root.location.protocol}//${root.location.hostname}`;

    public getDefaultParams = () => ({ brand: this.brand, region: this.region });

    private getLastOrderParams = () => ({ order_limit: 1, cart_items: 1 });

    public getSessionOrder = async (): Promise<IOrderHistoryResponse> => {
        const defaultParams = this.getDefaultParams();
        const lastOrderParams = this.getLastOrderParams();
        const url = `${this.getBaseUrl()}${BASE_API_PATH}/order_history`;

        const orderHistoryResponse = await this.httpTransport.get<IOrderHistoryResponse>({
            url,
            params: {
                ...defaultParams,
                ...lastOrderParams
            },
            options: {
                credentials: 'include'
            }
        });

        return orderHistoryResponse.data;
    };

    public getPageOrders = async (
        page: number,
        signal: AbortSignal
    ): Promise<IOrderHistoryResponse> => {
        const defaultParams = this.getDefaultParams();
        const url = `${this.getBaseUrl()}${BASE_API_PATH}/order_history`;

        const orderHistoryResponse = await this.httpTransport.get<IOrderHistoryResponse>({
            url,
            params: {
                ...defaultParams,
                full_details: 1,
                page
            },
            options: {
                credentials: 'include',
                signal
            }
        });

        return orderHistoryResponse.data;
    };
}
