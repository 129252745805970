import * as React from 'react';

import { computed, observable, set } from 'mobx';

import { DateFormatter, PriceFormatter } from '@estee/elc-formatters';

import { ICartItem, IOrder, IOrderSummaryCamel } from '~api/IOrderHistoryApi';
import { diContainer, serviceNames } from '~setup/diContainer';
import { camelCaseData } from '~utils/ToCamelCase';

import { OrderedItem } from './OrderedItem';

interface IStatusItem {
    id: number;
    label: string;
    completed: boolean;
}

export class Order {
    @observable public details: IOrderSummaryCamel;
    @observable public itemsOrdered: OrderedItem[];
    @observable public summary: IOrderSummaryCamel;
    @observable public transId: string;
    @observable public statusList: IStatusItem[] = [
        { id: 0, label: 'Waiting', completed: false },
        { id: 1, label: 'In Transit', completed: false },
        { id: 2, label: 'Completed', completed: false }
    ];
    private dateFormatter: DateFormatter;
    private priceFormatter: PriceFormatter;
    public itemsOrderedRefs: React.RefObject<HTMLDivElement>[];

    constructor() {
        this.dateFormatter = diContainer.get(serviceNames.dateFormatter);
        this.priceFormatter = diContainer.get(serviceNames.priceFormatter);
    }

    public static fromJSON(data: IOrder): Order {
        const orderInfo = new Order();
        const items = data.cart_items;

        const summary = camelCaseData(data.order_summary);

        const {
            carrierCode,
            shippedDate,
            trackingNumber,
            shipped,
            shipMethod,
            ...remainingSummary
        } = summary as IOrderSummaryCamel;

        const order = {
            details: {
                carrierCode,
                shippedDate,
                trackingNumber,
                shipped,
                shipMethod
            },
            itemsOrdered: items.map((item: ICartItem) => OrderedItem.fromJSON(item)),
            transId: data.trans_id,
            summary: remainingSummary
        };

        set(orderInfo, order);

        return orderInfo;
    }

    @computed public get price() {
        return this.priceFormatter.formatPriceWithCurrency(this.summary.totalCost);
    }

    @computed public get orderDate() {
        const [dateOnly] = this.summary.transDate.split('T');
        const orderDate = dateOnly.slice(2).replace(/-/g, '');

        const formattedDate = this.dateFormatter.formatDateWithPlaceholder({
            date: orderDate,
            dateFormat: 'MM/DD/YY',
            message: '::ORDER_DATE::',
            placeholderRegex: /::ORDER_DATE::/gi
        });

        return formattedDate;
    }

    @computed public get totalSteps(): number {
        return this.statusList.length;
    }

    @computed public get statusItem() {
        const { orderStatus } = this.summary;
        const [defaultStatus] = this.statusList;

        return this.statusList.find(({ id }) => id === orderStatus) || defaultStatus;
    }

    @computed public get statusLabels(): string[] {
        return this.statusList.map(({ label }: IStatusItem) => label);
    }

    @computed public get statusLabel(): string {
        return this.statusItem ? this.statusItem.label : '';
    }
}
