import { IAppConfig } from '@estee/elc-universal-utils';

interface IPerlgemConfig {
    abbrev?: string;
    id: string;
    literal?: string;
}

interface IRegionConfig extends IPerlgemConfig {
    perlgem_region_id: string;
}

export interface IServiceAppConfig extends IAppConfig {
    pgApiTokens: {
        [key: string]: string;
    };
}

interface IBUIDConfig {
    brand: IPerlgemConfig;
    currency: string;
    language: IPerlgemConfig;
    region: IRegionConfig;
}

export interface IServiceAppConfigs extends IServiceAppConfig, IBUIDConfig {}

export const brandIdPath = 'brand.id';
interface IBUIDPathedConfig extends IBUIDConfig {
    [brandIdPath]?: IPerlgemConfig['id'];
}

export interface IAppAndBUIDConfigs {
    appConfig: IServiceAppConfig;
    buidConfig: Partial<IBUIDPathedConfig>;
}

export interface IAppConfigWithRequestedBUIDKeys {
    appConfig: IServiceAppConfig;
    requestedBuidConfigs: string[];
}
